import { useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router';
import Dinero, { Currency } from 'dinero.js';

// GraphQL
import {
    DeleteHoldDocument,
    OrderSummaryEventQuery,
    PaymentPageEventQuery,
    TicketCategoryObject,
} from '../../graphql/graphql';

// MUI
import {
    Box,
    Stack,
    Typography,
    Divider,
    Checkbox,
    Modal,
    checkboxClasses,
    FormControl,
    Input,
    FormHelperText,
    Sheet,
    CircularProgress,
} from '@mui/joy';
import EditIcon from '@mui/icons-material/Edit';

// Components
import { CheckoutBtn } from './checkoutBtnJoy.component.tsx';

// Styled
import {
    StyledButton,
    StyledBorderButton,
    StyledActionButton,
} from '../common/styled/ActionablesJoy.styled.tsx';
import { CheckoutStack, MoneyBox } from './orderSummaryPageJoy.styled.tsx';
import { StyledModalBox } from '../payment/paymentPageJoy.styled.tsx';
import { StyledTextPrimary } from '../common/styled/StaticElementsJoy.styled.tsx';

// Images
import Logo from '../../assets/poweredByLogo.png';

// Functions
import { SafeChargeContext } from '../common/safecharge.provider';
import { ApplePayButtonComponent } from './ApplePayButton.component.tsx';
import { GooglePayButtonComponent } from './GooglePayButton.component.tsx';
import { CartContext } from '../providers/cart.tsx';
import { useApolloClient } from '@apollo/client';
import { LineItemType } from '../../utils/calculations.ts';

export interface CheckoutCardProps {
    event: OrderSummaryEventQuery['event'] | PaymentPageEventQuery['event'];
    organizerPrimaryColor: string;
    organizerSecondaryColor?: string;
    disablePurchase: boolean;
    paymentPage?: boolean;
    soldOut?: boolean;
    currency: string;
    setErrorAlert?: (err: string | null) => void;
}

export interface MatchedCategories extends TicketCategoryObject {
    quantitySelected: number;
}

export default function CheckoutCard({
    event,
    organizerPrimaryColor,
    disablePurchase = false,
    paymentPage = false,
    soldOut = false,
    setErrorAlert,
}: CheckoutCardProps) {
    const history = useHistory();
    const [editCheckout, setEditCheckout] = useState(false);
    const [agreedToToS, setAgreedtoToS] = useState(false);
    const [specialRequests, setSpecialRequests] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const apollo = useApolloClient();

    const { cart, getCalculations } = useContext(CartContext);

    const { lineItems, feeItems, total } = useMemo(
        () => getCalculations(event),
        [getCalculations, event]
    );

    const hasTickets = useMemo(
        () => lineItems.filter((item) => item.type === LineItemType.TICKET_LINE_ITEM).length > 0,
        [lineItems]
    );

    useEffect(() => {
        if (agreedToToS && lineItems.length === 0) {
            setAgreedtoToS(false);
        }
    }, [lineItems, agreedToToS]);

    const goBack = async () => {
        try {
            if (cart.hold?.id) {
                await apollo.mutate({
                    mutation: DeleteHoldDocument,
                    variables: {
                        deleteHoldId: cart.hold.id,
                    },
                });
            }

            history.goBack();
        } catch (err) {
            console.error(err);
            history.goBack();
        }
    };

    const safeCharge = useContext(SafeChargeContext);

    const isEnabled = Boolean(
        hasTickets && !disablePurchase && !soldOut && safeCharge && agreedToToS
    );

    const showCheckoutNotesField = Boolean(event?.checkoutCustomFields?.[0]?.id);

    return (
        <>
            <Modal open={editCheckout} onClose={() => setEditCheckout(false)}>
                <StyledModalBox>
                    <Stack>
                        <Typography sx={{ fontWeight: 500, fontSize: 24, marginBottom: 3 }}>
                            Leave checkout to edit order?
                        </Typography>
                        <Box
                            sx={{
                                alignSelf: 'flex-end',
                                display: 'flex',
                                alignItems: 'flex-start',
                            }}
                        >
                            <StyledBorderButton
                                sx={{ marginTop: '0 !important', marginRight: '10px' }}
                                onClick={() => setEditCheckout(false)}
                                backgroundcolor={organizerPrimaryColor}
                            >
                                No, stay in Checkout
                            </StyledBorderButton>
                            <StyledButton backgroundcolor={organizerPrimaryColor} onClick={goBack}>
                                Yes, I'd like to edit my order
                            </StyledButton>
                        </Box>
                    </Stack>
                </StyledModalBox>
            </Modal>
            <Stack sx={{ position: { lg: 'sticky', sm: 'relative' }, top: 15 }}>
                <CheckoutStack
                    sx={{ backgroundColor: (theme) => theme.palette.neutral[50], marginBottom: 2 }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography level={'body-md'} sx={{ fontWeight: 600 }}>
                            Checkout
                        </Typography>
                        {paymentPage && (
                            <StyledActionButton
                                id={'edit_order_button'}
                                variant="plain"
                                onClick={() => setEditCheckout(true)}
                                setcolor={organizerPrimaryColor}
                                startDecorator={<EditIcon />}
                            >
                                Edit Order
                            </StyledActionButton>
                        )}
                    </Box>
                    <>
                        <Stack sx={{ my: 3 }}>
                            {lineItems?.length > 0 ? (
                                lineItems.map((item) => {
                                    return (
                                        <Box
                                            key={item.id}
                                            sx={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    lineHeight: '150%',
                                                }}
                                            >
                                                {item.quantity} x {item.name}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontSize: 16,
                                                    fontWeight: 700,
                                                    lineHeight: '150%',
                                                }}
                                            >
                                                {Dinero({
                                                    amount: Number(item.subTotal),
                                                    currency: 'USD',
                                                }).toFormat('$0,0.00')}
                                            </Typography>
                                        </Box>
                                    );
                                })
                            ) : (
                                <Typography sx={{ my: 3, fontSize: 16 }}>--</Typography>
                            )}
                        </Stack>
                        {feeItems
                            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
                            .map((fee) => (
                                <MoneyBox key={fee.id}>
                                    <Typography>{fee.name}</Typography>
                                    <Typography>
                                        {Dinero({
                                            amount: fee.subTotal,
                                            currency: event!.currency as Currency,
                                        })
                                            .setLocale(
                                                event!.currency === 'USD' ? 'en-US' : 'en-CA'
                                            )
                                            .toFormat('$0,0.00')}
                                    </Typography>
                                </MoneyBox>
                            ))}
                        <MoneyBox>
                            <Typography sx={{ fontWeight: '700' }}>
                                Total ({event!.currency}):
                            </Typography>
                            <Typography sx={{ fontWeight: '700' }}>
                                {Dinero({
                                    amount: total,
                                    currency: event!.currency as Currency,
                                })
                                    .setLocale(event!.currency === 'USD' ? 'en-US' : 'en-CA')
                                    .toFormat('$0,0.00')}
                            </Typography>
                        </MoneyBox>
                        <Stack sx={{ mt: 3 }}>
                            <Typography sx={{ lineHeight: '143%', fontWeight: '700' }}>
                                All amounts are in{' '}
                                <span style={{ fontWeight: 600 }}>
                                    {event!.currency === 'USD' ? 'US Dollars' : 'Canadian Dollars'}
                                </span>
                                .
                            </Typography>
                            <Typography sx={{ lineHeight: '143%' }}>
                                Applicable fees are not refundable.
                            </Typography>
                        </Stack>
                    </>
                    {!paymentPage && (
                        <>
                            {showCheckoutNotesField && (
                                <Stack sx={{ mt: 3 }}>
                                    <StyledTextPrimary
                                        level="body-sm"
                                        sx={{ fontWeight: 600, mb: 0.5 }}
                                    >
                                        Special Requests (Optional)
                                    </StyledTextPrimary>
                                    <FormControl
                                        id="special-requests-control"
                                        size="md"
                                        color="neutral"
                                        error={false}
                                        sx={{ width: '100%' }}
                                    >
                                        <Input
                                            color="neutral"
                                            id="special-requests"
                                            placeholder="Special Requests (Optional)"
                                            name="special-requests"
                                            disabled={lineItems.length === 0}
                                            onChange={(e) => {
                                                if (e.target.value.length <= 100) {
                                                    setSpecialRequests(e.target.value);
                                                }
                                            }}
                                            fullWidth
                                            value={specialRequests}
                                            variant="outlined"
                                            sx={{
                                                '&:focus-within::before': {
                                                    boxShadow: `inset 0px 0px 1px 1px ${organizerPrimaryColor}!important`,
                                                },
                                            }}
                                        />
                                        <FormHelperText
                                            sx={{ display: 'flex', justifyContent: 'end' }}
                                        >
                                            {specialRequests?.length ?? 0}/100
                                        </FormHelperText>
                                    </FormControl>
                                </Stack>
                            )}
                            <Stack
                                flexDirection={'row'}
                                sx={{ my: '20px' }}
                                justifyContent={'left'}
                            >
                                <Checkbox
                                    id={'terms_and_conditions_checkbox'}
                                    value="agreedWithTermsOfService"
                                    checked={agreedToToS}
                                    disabled={!hasTickets}
                                    onChange={(e) => setAgreedtoToS(e.target.checked)}
                                    sx={{
                                        [`.MuiCheckbox-checkbox`]: {
                                            borderColor: organizerPrimaryColor,
                                        },
                                        [`.MuiCheckbox-checkbox.${checkboxClasses.disabled}`]: {
                                            borderColor: (theme) => theme.palette.neutral[400],
                                        },
                                        [`.MuiCheckbox-checkbox.${checkboxClasses.checked}`]: {
                                            backgroundColor: organizerPrimaryColor,
                                        },
                                    }}
                                />
                                <Typography sx={{ lineHeight: '143%', marginLeft: 1 }}>
                                    I accept the{' '}
                                    <a
                                        style={{
                                            color: organizerPrimaryColor,
                                            textDecoration: 'none',
                                        }}
                                        href={'https://community.admitone.com/terms-of-service'}
                                        target={'_blank'}
                                    >
                                        AdmitONE Terms of Service.
                                    </a>{' '}
                                    (Required)
                                </Typography>
                            </Stack>
                            <Stack
                                sx={{
                                    height: isEnabled && total > 0 ? 'auto' : 0,
                                    visibility: isEnabled && total > 0 ? 'visible' : 'hidden',
                                }}
                                gap={1.5}
                            >
                                <ApplePayButtonComponent
                                    event={event! as OrderSummaryEventQuery['event']}
                                    chargeAmount={total}
                                    chargeCurrency={event!.currency}
                                    checkoutNotes={specialRequests}
                                    setErrorAlert={setErrorAlert!}
                                />
                                <GooglePayButtonComponent
                                    event={event! as OrderSummaryEventQuery['event']}
                                    chargeAmount={total}
                                    chargeCurrency={event!.currency}
                                    checkoutNotes={specialRequests}
                                    setLoading={setLoading}
                                    setErrorAlert={setErrorAlert!}
                                />
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        mb: 1.5,
                                    }}
                                >
                                    <Divider sx={{ flexGrow: 1, alignSelf: 'center' }} />
                                    <Typography sx={{ px: 2 }}>OR</Typography>
                                    <Divider sx={{ flexGrow: 1, alignSelf: 'center' }} />
                                </Box>
                            </Stack>

                            <CheckoutBtn
                                history={history}
                                backgroundcolor={organizerPrimaryColor}
                                isAuthenticated={false}
                                disabled={!isEnabled}
                                btnText={soldOut ? 'Sold Out' : 'Proceed to Checkout'}
                                eventId={event!.id}
                                checkoutNotes={specialRequests}
                                chargeAmount={total}
                                chargeCurrency={event!.currency}
                            />
                        </>
                    )}
                </CheckoutStack>
                <div>
                    <img
                        src={Logo}
                        alt="Powered by AdmitONE"
                        style={{ width: '100%', maxWidth: '150px' }}
                    />
                </div>
            </Stack>
            <Modal
                aria-labelledby="alert-dialog-title"
                aria-describedby="modal-alert-dialog-description"
                open={loading}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Sheet
                    variant="outlined"
                    sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
                >
                    <Typography
                        component="h2"
                        id="modal-title"
                        level="title-lg"
                        textColor="inherit"
                        sx={{ mb: 2 }}
                    >
                        {'Processing...'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                        <CircularProgress sx={{ color: organizerPrimaryColor }} />
                    </Box>
                    <Typography id="alert-dialog-description" level="body-lg">
                        We are processing your order. Please do not close or refresh this browser
                        tab.
                    </Typography>
                </Sheet>
            </Modal>
        </>
    );
}
